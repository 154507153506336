<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        The Role of Storage in Lab Operation
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1637746491/virolog-coducting-experiment-course-coronavirus-pandemic-with-micropipette-chemist-modern-laboratory-doing-research-using-dispenser-during-global-epidemic-with-covid-19_xdmyay.jpg"
            alt=""
            width="100%"
            style="max-height: 32rem;width: 100%;object-fit: contain"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            The Role of Storage in Lab Operation
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: Nov 24, 2021</p>
            </span>
          </div>
          <p>
            According to the CDC, 14 billion lab tests are ordered annually, and
            70% of medical decisions depend on laboratory results. This data
            gives a background to the level of importance laboratories hold
            within medical space.
          </p>
          <p>
            In the lab industry, one of the most important aspects of management
            and operation is Storage. Haphazard collection and storage of data
            and items within the lab lead to confusion; data and items can be
            misplaced before analysis, preventing work from being verified,
            obstructing the progress of research as well as procedures of
            advancement. Safely storing chemicals in a laboratory or stockroom
            requires diligence and careful consideration. Correct use of
            containers and common lab equipment is critical.
          </p>
          <p>
            Proper storage saves time. Actually, whether you are a professional
            or not, keeping your tools properly stored, cleaned, and maintained
            will save you time and money and make all your endeavours that much
            more rewarding. However, bad storage practices could be costing you
            a lot - not just in terms of paying for space you don't necessarily
            need, but also in poor productivity when items aren't easy to
            locate. Anything a business can do to boost productivity pays off,
            and effective storage plays a part.
          </p>
          <p>
            When chemicals are easily accessible and stored in safe conditions,
            this hastens the speed and efficiency of research work and
            diagnostic test. Imagine, at the outbreak of a pandemic, scientists
            with a rough and disorganized workspace, such space is bound to
            produce ineffective vaccines, slowing the results, further
            endangering many lives in the process.
          </p>
          <p>
            By ensuring that laboratory items are stored properly, you reduce a
            likely eruption of hazardous events, unwanted reactions caused by
            the mixture of incompatible chemicals, not to mention the threat to
            the credibility of the lab is mitigated.
          </p>
          <p>
            In avoiding storage related inefficiencies, it is best to take up
            the following principles:
          </p>

          <p class="font-weight-bold mb-3">
            Labeling
          </p>
          <p>
            During storage, all chemical containers must be labelled with the
            identity of chemical(s) therein and Appropriate Hazards Warnings, in
            words, pictures, symbols, or a combination thereof which provide all
            of the hazard information. Labels on containers with mixtures of
            different chemicals must indicate the constituents and approximate
            percentages of each. Including the owner’s or user’s name along with
            the date received is highly recommended.
          </p>

          <p class="font-weight-bold">
            Ensure Appropriate Storage Conditions
          </p>
          <p>
            Specimens, chemicals and other assets in the lab are best stored at
            a certain condition else, their integrity becomes questionable or
            worse they could cause an accident.
          </p>
          <p>
            For example test samples like blood, saliva and urine are best kept
            in an airtight container then stored in a refrigerator to ensure all
            components of the sample, including the possibly present viruses and
            bacteria remain intact, this helps ensure the results generated when
            tested are credible.
          </p>
          <p>
            Volatile chemicals are also best stored in a refrigerator as they
            are readily reactive, and when subjected to certain conditions, they
            could explode, possibly causing a more major explosion in an unlucky
            turn of events, or even poison the atmosphere in the lab, depending
            on their nature.
          </p>
          <p>
            Volatile chemicals are not always stored in large enough quantities
            to require a mass evacuation. But, the risks of storing any chemical
            outside its safe temperature range are still significant.
          </p>
          <p class="font-weight-bold">
            Separation
          </p>
          <p>
            Hazardous chemicals should be separated from unsuitable conditions
            due to their toxicity, flammability or reactivity. Storage cupboards
            that divide incompatible classes of hazardous chemicals can help to
            achieve this. Provide specific storage space for each chemical, and
            ensure return after each use. Separating them by distance in other
            buildings is also effective in this regard.
          </p>
          <p>
            Separate all chemicals, especially liquids, according to compatible
            groups. Follow all precautions regarding the storage of incompatible
            materials. Post a chemical compatibility chart for reference, both
            in the lab and next to chemical storage rooms. Use appropriate
            resistant secondary containers for corrosive materials. This
            protects the cabinets and will catch any leaks or spills due to
            breakage. Seal containers tightly to prevent the escape of vapours.
          </p>

          <p class="font-weight-bold">
            Segregation
          </p>
          <p>
            Strong acids should be kept away from strong bases, while strong
            oxidisers should be kept from organic or flammable materials.
            Highly-flammable liquids, meanwhile, should be kept away from all
            other materials. Store flammable liquids in approved flammable
            liquid storage cabinets. Small amounts of flammable liquids may be
            stored in the open room. Check with your local authority for
            allowable limits
          </p>
          <p>
            Use designated refrigerators for storing chemicals. Never store
            flammable liquids in a refrigerator unless it is specifically
            designed and approved for such storage. Use only explosion-proof
            (spark-free) refrigerators for storing flammables. Indicate on the
            refrigerator thus: CHEMICAL MATERIALS NOT FOOD.
          </p>

          <p class="font-weight-bold">
            Ventilation
          </p>
          <p>
            Another essential requirement for the safe storage of hazardous
            chemicals, ventilation allows for the dilution and extraction of
            vapours or gases that may escape from containers. Store volatile
            toxics and odoriferous chemicals in ventilated cabinets. Please
            check with your environmental health and safety personnel for
            specific guidance.
          </p>

          <p class="font-weight-bold">
            In Conclusion...
          </p>
          <p>
            Aside from the storage of chemicals, another way to look at storage
            is in terms of tangible and intangible data keeping. Good record
            keeping can help users track errors or refine an experiment. Data
            collection and storage are essential for a properly functioning
            laboratory. Records can be used to audit a lab or to check the
            data’s integrity.
          </p>
          <p>
            The amount of data that you need to collect, store and analyse will
            have an impact on deciding which method is best for managing it. If
            the samples that you are weighing are not vital to an operation or
            do not need to be tracked, then you might only need temporary
            storage. Other factors, like the importance of the data, the length
            of time you need to save and how often it needs to be accessed, will
            have an impact on how you collect and store it. Correct data
            handling is integral and offers many benefits to a lab, from
            improving productivity to boosting credibility.
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
// import image5 from "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg";
import image6 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
import image7 from "../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg";
// import image5 from "../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg";
import image8 from "../../assets/blog new/pharmaceuticall.png";
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    loaded: false,
    loading: true,
    info: ["News", "Health", "Logistics"],
    rightSide: [
      {
        img: image7,
        content:
          "Digitalization in the African retail pharmaceutical sector (With Shelflife by Field intelligence and mPharma)",
        date: "Sept 19, 2021",
        link:
          "/blog/digitalisation-in-the-retail-pharmaceutical-sector-shelf-life-director-of-pharmacy-suleman-Sule-and-mPharmas-joyce-aderinlewo-talked-about-how-they-are-helping-thousands-of-community-pharmacies-use-data-and-technology-to-optimise-operations",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          "Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "Sept 12, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image8,
        content:
          "Gricd works with ISN to assure product integrity and avert waste risk.",
        date: "Sept 18, 2021",
        link:
          "/blog/gricd-works-with-isn-to-assure-product-integrity-and-avert-waste-risk",
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          "INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma And Its Impact On The Health Of Nigerians",
        date: "July 19, 2021",
        link:
          "/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          " Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "June 13, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image6,
        content:
          " Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
        date: "June 13, 2021",
        link:
          "/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
      },
    ],
    mySVG: require("../../assets/Logo/search.svg"),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://gricd.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: "https://gricd.com/blog",
              },
              {
                "@type": "ListItem",
                position: 3,
                name:
                  "Medical Consultant Speaks On The Role of Storage In Managing Diabetes.",
                item:
                  "https://gricd.com/blog/medical-consultant-speaks-on-the-role-of-storage-in-managing-diabetes",
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
// p span {
//   text-decoration: underline;
//   color: #28a84a;
// }
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
.grey-text {
  color: #676464;
}
.pic-size {
  width: 28rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
